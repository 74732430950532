import { fg } from '@confluence/feature-gating';

import { LIVE_PAGES_FEATURE_GATE, LIVE_PAGES_AB_TEST_OPTED_IN } from './featureFlags';

export const useIsLivePagesFeatureEnabled = (): boolean => {
	// eslint-disable-next-line confluence-feature-gating/static-feature-gates
	const isLivePagesEnabled = fg(LIVE_PAGES_FEATURE_GATE);
	// eslint-disable-next-line confluence-feature-gating/static-feature-gates
	const isLivePagesABTestOptedIn = fg(LIVE_PAGES_AB_TEST_OPTED_IN);
	return isLivePagesEnabled || isLivePagesABTestOptedIn;
};
